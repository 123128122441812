import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FormComp from '../components/form'
import ThankYou from '../components/thankYou'
import {graphql} from 'gatsby'
import Img from "gatsby-image"


class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {displayForm: true}
        this.displayThankYouFunc = this.displayThankYouFunc.bind(this)
    }

    displayThankYouFunc() {
        this.setState({displayForm: false})
    }

    render() {
    return (
        <Layout>
            <SEO title="Kontakt" />
        
            <div id="contact-main-wrapper" className="mt-16 mb-32" data-aos="fade-in"  data-aos-duration="1000" data-aos-delay="400">
                <div id="main-section" className="flex flex-col md:flex-row md:flex-start items-center">
                    <div id="picture" className="hidden lg:block w-1/2 mr-32">
                        <Img fluid={this.props.data.contentfulAsset.fluid}/>
                    </div>

                    <div id="form-section" className="flex flex-col">
                        

                        {this.state.displayForm
                            ? <FormComp componentChange={this.displayThankYouFunc}/>
                            : <ThankYou />}
                        
                    <div className="flex flex-row text-xl mt-10">
                            <p> Oder schreib uns eine E-Mail <br /> <a href="mailto:hallo@stepone.at" className="underline hover:text-soYellow"> hallo@stepone.at </a></p>
                    </div>

                    </div>
                </div>
            </div>

        </Layout>
    )}

}

export const query = graphql`
  {
    contentfulAsset(title: {eq: "KontaktBild"}) {
      id
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
  }
`

export default ContactPage;