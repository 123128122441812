import React, {useEffect} from 'react';

import { Formik, Field} from "formik";
import * as Yup from "yup";
import axios from "axios";

import '../styles/kontakt.scss'

const FormComp = ({componentChange}) => {

    useEffect(() => {
        var checkbox = document.querySelectorAll('#ck-button')
        var i = 0
        for (i=0; i < checkbox.length; i++) {
            checkbox[i].children[0].children[0].addEventListener('change', function() {
                this.parentNode.parentNode.classList.toggle('active-checkbox')
            })
        }
    })


    

    function auto_grow() {
        const element = document.querySelector('#message');
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
    }


    return (
        <div>
        <h1 id="title" className="text-3xl">Sag doch mal Hallo!</h1>
        <Formik
            initialValues={{ name: "", email: "", company: "", message: "" }}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                .required("Bitte gib deinen Namen an"),
                email: Yup.string()
                .email()
                .required("Bitte gib deine E-Mail Adresse an"),
                message: Yup.string()
                .required("Bitte erzähl uns worum es in deinem Projekt geht")
                .min(3)
            })}

            onSubmit={(values) => {
                axios.post('https://getform.io/f/aa1ebce2-aef5-45c8-ac5b-d755f206f362', {
                    name: values.name,
                    company: values.company,
                    category: values.category,
                    email: values.email,
                    message: values.message
                })
                .then(res => {
                    componentChange()
                })
                .catch(err => {
                });
            }}
        >
            {
            props => {
                const { 
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props;
            

                return (
                    

                    

                    <form onSubmit={props.handleSubmit} className='form'>

                        <div className="mt-6 text-xl text-soGray" id="checkbox-group">Worum geht's?</div>

                        <div role="group" aria-labelledby="checkbox-group" className="mb-24 md:mb-16 block h-48">

                            <div id="ck-button" className="w-32 md:w-40 h-16">
                                <label htmlFor="branding">
                                    <Field type="checkbox" name="category" value="Branding" id="branding"/>
                                    <span id="checkbox-span">Branding</span>
                                </label>
                            </div>

                            <div id="ck-button" className="w-32 md:w-40 h-16">
                                <label htmlFor="ui/ux">
                                    <Field type="checkbox" name="category" value="UI/UX" id="ui/ux"/>
                                    <span id="checkbox-span">UI/UX</span>
                                </label>
                            </div>

                            <div id="ck-button" className="w-32 md:w-40 h-16">
                                <label htmlFor="editorial">
                                    <Field type="checkbox" name="category" value="Editorial" id="editorial"/>
                                    <span id="checkbox-span">Editorial</span>
                                </label>
                            </div>

                            <div id="ck-button" className="w-32 md:w-40 h-16">
                                <label htmlFor="werbung">
                                    <Field type="checkbox" name="category" value="Werbung" id="werbung"/>
                                    <span id="checkbox-span">Werbung</span>
                                </label>
                            </div>
                        
                            <div id="ck-button" className="w-32 md:w-40 h-16">
                                <label htmlFor="strategie">
                                    <Field type="checkbox" name="category" value="Strategie" id="strategie"/>
                                    <span id="checkbox-span">Strategie</span>
                                </label>
                            </div>

                        </div>


                        {/*<label htmlFor="name" className="my-2" style={{ display: "block" }}>
                            Dein Name
                        </label>*/}
                        <div id="form-item" className="my-12 md:my-16">
                            <input
                                className="my-2 h-3xl text-xl md:text-2xl border-none input-field block w-full"
                                name="name"
                                id="name"
                                placeholder="Dein Name*"
                                type="text"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <div className="line bg-soGray w-full" />
                            {errors.name && touched.name && (
                                <div className="py-2 text-soGray">{errors.name}</div>
                            )}
                        </div>

                        {/*<label htmlFor="company" className="my-2" style={{ display: "block" }}>
                            Dein Unternehmen
                        </label>*/}

                        <div id="form-item" className="my-12 md:my-16">
                            <input
                                className="my-2 h-3xl text-xl md:text-2xl border-none input-field block w-full"
                                name="company"
                                id="company"
                                placeholder="Dein Unternehmen"
                                type="text"
                                value={values.company}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <div className="line bg-soGray w-full" />
                        </div>

                        

                        {/*<label htmlFor="email" className="my-2" style={{ display: "block" }}>
                            E-Mail Adresse
                        </label>*/}
                        <div id="form-item" className="my-12 md:my-16">
                            <input
                                className="my-2 h-3xl text-xl md:text-2xl border-none input-field block w-full"
                                name="email"
                                id="email"
                                placeholder="Deine E-Mail*"
                                type="text"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <div className="line bg-soGray w-full" />
                            {errors.email && touched.email && (
                            <div className="py-2 text-soGray">{errors.email}</div>
                            )}
                        </div>


                        {/*<label htmlFor="message" className="my-2" style={{ display: "block" }}>
                            Deine Nachricht
                        </label>*/}

                        <div id="form-item" className="my-12 md:my-16">
                            <textarea
                                className="my-2 h-3xl text-xl md:text-2xl border-none input-field block w-full"
                                name="message"
                                id="message"
                                placeholder="Erzähl uns von deinem Projekt*"
                                type="text"
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onInput={auto_grow}
                            />
                            <div className="line bg-soGray w-full" />
                            {errors.message && touched.message && (
                            <div className="py-2 text-soGray">{errors.message}</div>
                            )}
                        </div>

                        
                    
                        {/*<button
                        type="button"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                        >
                        Formular zurücksetzen
                        </button>*/}

                        <button type="submit" onClick={handleSubmit} disabled={isSubmitting} className="w-40 h-16 bg-black text-white rounded-lg border-2 border-black hover:bg-white transition-all duration-500 hover:text-black">
                        Absenden
                        </button>

                    </form>
                );
            }}
            
        </Formik>
    </div>
    )
}

export default FormComp;