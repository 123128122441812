import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';

const ThankYou = () => {

    useEffect(() => {
        anime({
            target: '#thank-you-container',
            duration: 2000,
            opacity: [0,1]
        })
    })


    return (
        <div id="thank-you-container" className="flex flex-col justify-center">
            <h1 className="text-3xl mb-6">Vielen Dank für deine Anfrage!</h1>
            <p className="text-soGray">Wir melden uns sobald wie möglich, um weitere Details mit dir zu besprechen.</p>
        </div>
    )
}

export default ThankYou;